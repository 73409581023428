
import { computed, onMounted } from "vue";
import { CLUSTERS } from "./solana/connection";
import { changeCluster, chosenCluster } from "./solana/connection";

export default {
  name: "App",
  setup() {
    changeCluster(chosenCluster.value);

    const isOnMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

    const currentClusterWithEmoji = computed(() => {
      let emoji;
      switch (chosenCluster.value) {
        case "mainnet-beta":
          emoji = "";
          break;
        case "testnet":
          emoji = "";
          break;
        case "devnet":
          emoji = "";
          break;
        case "localnet":
          emoji = "";
          break;
      }
      return chosenCluster.value + " " + emoji;
    });

    onMounted(() => {
      // workaround for bulma bug -- navbar dropdowns not closing after route change
      document
        .getElementsByClassName("navbar")[0]
        .querySelectorAll(".has-dropdown")
        .forEach(el => {
          el.addEventListener("click", () => {
            let menu = el.querySelector(".navbar-dropdown");
            //@ts-expect-error
            menu.style.display = "none";
            setTimeout(() => {
              //@ts-expect-error
              el.blur();
              // Reset the display property to its original state, so the menu can appear again next time
              //@ts-expect-error
              menu.style.display = "";
            }, 200);
          });
        });
    });

    return {
      CLUSTERS,
      chosenCluster,
      changeCluster,
      isOnMobile,
      currentClusterWithEmoji
    };
  }
};
